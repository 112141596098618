/**
 * @generated SignedSource<<de2f659949b673cbf14b82f9e18fee3b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProductTileMeasurements_item$data = {
  readonly measurement: {
    readonly display: ReadonlyArray<{
      readonly unit: string | null;
      readonly value: string | null;
    } | null> | null;
    readonly size: {
      readonly value: string | null;
    } | null;
  } | null;
  readonly " $fragmentType": "ProductTileMeasurements_item";
};
export type ProductTileMeasurements_item$key = {
  readonly " $data"?: ProductTileMeasurements_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProductTileMeasurements_item">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProductTileMeasurements_item",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ItemMeasurement",
      "kind": "LinkedField",
      "name": "measurement",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "addLabel",
              "value": true
            },
            {
              "kind": "Literal",
              "name": "fields",
              "value": [
                "height",
                "width",
                "depth",
                "diameter",
                "length"
              ]
            },
            {
              "kind": "Literal",
              "name": "separator",
              "value": " "
            }
          ],
          "concreteType": "ItemDisplayMeasurementType",
          "kind": "LinkedField",
          "name": "display",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "unit",
              "storageKey": null
            },
            (v0/*: any*/)
          ],
          "storageKey": "display(addLabel:true,fields:[\"height\",\"width\",\"depth\",\"diameter\",\"length\"],separator:\" \")"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ItemSize",
          "kind": "LinkedField",
          "name": "size",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Item",
  "abstractKey": null
};
})();

(node as any).hash = "f80c7ed0f585593869baad4f71176fc1";

export default node;
