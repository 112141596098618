import {
    type FC,
    type ReactNode,
    type ComponentProps,
    createContext,
    useContext,
    useMemo,
    useState,
    useCallback,
    useRef,
    useEffect,
} from 'react';
import { VisibilityTracker } from 'dibs-visibility-tracker/exports/VisibilityTracker';
import { type OnSlideChange } from '../types';

type SetCurrentIndex = ({
    index,
    backfillStartCount,
}: {
    index: number;
    backfillStartCount: number;
}) => void;

type ScrollCarouselContextType = {
    currentIndex: number;
    setCurrentIndex: SetCurrentIndex;
    totalItems: number;
    largestItemsToShow: number;
    stepSize: number;
    isInfinite: boolean;
    totalSlides: number;
    activeSlide: number;
    resetCurrentIndex: () => void;
};

const ScrollCarouselContext = createContext<ScrollCarouselContextType | null>(null);

export const useScrollCarousel = (): ScrollCarouselContextType => {
    const context = useContext(ScrollCarouselContext);

    if (!context) {
        throw new Error('useScrollCarousel must be used within a ScrollCarouselProvider');
    }

    return context;
};

export const ScrollCarouselProvider: FC<{
    children: ReactNode;
    totalItems: number;
    largestItemsToShow?: number;
    stepSize?: number;
    isInfinite?: boolean;
    autoplay?: boolean;
    autoplaySpeed?: number;
    onSlideChange?: OnSlideChange;
}> = ({
    children,
    totalItems,
    largestItemsToShow = 1,
    stepSize = 1,
    isInfinite = false,
    autoplay = false,
    autoplaySpeed = 5000,
    onSlideChange = () => {},
}) => {
    if (autoplay && !isInfinite) {
        throw new Error('Autoplay can only be used with infinite carousels');
    }

    const [currentIndex, _setCurrentIndex] = useState(0);

    const totalSlides = Math.ceil(totalItems / stepSize);
    const [activeSlide, setActiveSlide] = useState(1);

    const lastBackfillStartCountRef = useRef(0);

    const handleActiveSlide = useCallback<SetCurrentIndex>(
        ({ index, backfillStartCount }) => {
            let activeSlideIndex = index;
            const lastItemIndex = totalItems - 1 + backfillStartCount;

            if (index < backfillStartCount) {
                activeSlideIndex = index + (totalItems - backfillStartCount);
            } else if (index > lastItemIndex) {
                activeSlideIndex = index - totalItems - backfillStartCount;
            } else {
                activeSlideIndex = index - backfillStartCount;
            }

            const _activeSlide = Math.ceil((activeSlideIndex + 1) / stepSize);

            if (activeSlide !== _activeSlide) {
                onSlideChange({ activeSlide: _activeSlide });
                setActiveSlide(_activeSlide);
            }
        },
        [stepSize, totalItems, activeSlide, onSlideChange]
    );

    const setCurrentIndex = useCallback<SetCurrentIndex>(
        ({ index, backfillStartCount }) => {
            lastBackfillStartCountRef.current = backfillStartCount;
            _setCurrentIndex(index);
            handleActiveSlide({ index, backfillStartCount });
        },
        [handleActiveSlide]
    );

    const resetCurrentIndex = useCallback(() => {
        setCurrentIndex({
            index: lastBackfillStartCountRef.current,
            backfillStartCount: lastBackfillStartCountRef.current,
        });
    }, [setCurrentIndex]);

    const contextValue = useMemo(
        () => ({
            currentIndex,
            setCurrentIndex,
            totalItems,
            largestItemsToShow,
            stepSize,
            isInfinite,
            totalSlides,
            activeSlide,
            resetCurrentIndex,
        }),
        [
            currentIndex,
            totalItems,
            largestItemsToShow,
            stepSize,
            isInfinite,
            activeSlide,
            totalSlides,
            setCurrentIndex,
            resetCurrentIndex,
        ]
    );

    const isAutoplay = autoplay && autoplaySpeed && isInfinite;
    const [startAutoplay, setStartAutoplay] = useState(isAutoplay);

    useEffect(() => {
        if (startAutoplay && isAutoplay) {
            const interval = setTimeout(() => {
                setCurrentIndex({
                    index: currentIndex + stepSize,
                    backfillStartCount: lastBackfillStartCountRef.current,
                });
            }, autoplaySpeed);

            return () => {
                clearInterval(interval);
            };
        }

        return () => {};
    }, [startAutoplay, isAutoplay, autoplaySpeed, setCurrentIndex, currentIndex, stepSize]);

    const onVisibilityChange = useCallback<
        ComponentProps<typeof VisibilityTracker>['onVisibilityChange']
    >(({ isVisible }) => {
        setStartAutoplay(isVisible);
    }, []);

    const onMouseEnter = useCallback(() => {
        setStartAutoplay(false);
    }, []);
    const onMouseLeave = useCallback(() => {
        setStartAutoplay(true);
    }, []);

    const visibilityContainerRef = useRef<HTMLDivElement>(null);
    let childrenToRender = children;

    if (isAutoplay) {
        childrenToRender = (
            <div
                ref={visibilityContainerRef}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
            >
                <VisibilityTracker
                    watchAfterFirstVisible
                    elementRef={visibilityContainerRef}
                    onVisibilityChange={onVisibilityChange}
                />
                {children}
            </div>
        );
    }

    return (
        <ScrollCarouselContext.Provider value={contextValue}>
            {childrenToRender}
        </ScrollCarouselContext.Provider>
    );
};
