import { type FC, type ReactNode } from 'react';

// Component
import { PriceLabel } from './PriceLabel';

type RetailProps = {
    currency: string;
    label?: ReactNode;
    price: number;
    quantityDisplay?: string | null;
    showPriceVariability?: boolean | null;
};

export const Retail: FC<RetailProps> = ({
    currency,
    price,
    label,
    quantityDisplay,
    showPriceVariability = false,
}) => {
    return (
        <PriceLabel
            price={price}
            label={label}
            quantityDisplay={quantityDisplay}
            currency={currency}
            showPriceVariability={showPriceVariability}
        />
    );
};
