import {
    type FC,
    type CSSProperties,
    type ReactNode,
    type MouseEvent,
    type MouseEventHandler,
    useState,
    useRef,
} from 'react';

import classnames from 'classnames';
import styles from './main.scss';
import { useIntl } from 'dibs-react-intl';
import { handleLocaleUrl } from 'dibs-intl/exports/urls';

export const PARTIALLY_LINKABLE_LINK = 'PARTIALLY_LINKABLE_LINK';
export const ALWAYS_LINKABLE_LINK = 'ALWAYS_LINKABLE_LINK';
export const NO_CONTENT_LINK = 'NO_CONTENT_LINK';

type BuyerLinkType =
    | 'standardLink'
    | 'standardLinkNoUnderline'
    | 'invertedLink'
    | 'contrastLinkLightInverse'
    | 'contrastLinkLight'
    | 'contrastLinkAlternate'
    | 'contrastLinkNoEffect'
    | 'standardLinkNoEffect'
    | 'linkNoDecoration';

export type LinkData = {
    path: string | null;
    isLinkable?: boolean | null;
};

/*
 * made this an interface so that it can be extended by type merging
 * the `linkData` prop can be overloaded by the consuming component
 */
export interface Props {
    children?: ReactNode;
    onClick?: MouseEventHandler<HTMLElement>;
    linkData?: LinkData;
    dataTn?: string;
    dataPk?: string;
    className?: string;
    variant?: typeof PARTIALLY_LINKABLE_LINK | typeof ALWAYS_LINKABLE_LINK | typeof NO_CONTENT_LINK;
    style?: CSSProperties;
    role?: string;
    tabIndex?: number;
    target?: string;
    ariaLabel?: string;
    ariaDescribedBy?: string;
    buyerLinkType?: BuyerLinkType;
}

export const SeoLink: FC<Props> = ({
    children,
    linkData,
    onClick = () => {},
    dataTn,
    dataPk,
    className,
    variant = ALWAYS_LINKABLE_LINK,
    style,
    role,
    tabIndex = 0,
    target,
    ariaLabel,
    ariaDescribedBy,
    buyerLinkType,
}) => {
    const path = linkData?.path;
    const isLinkable = linkData?.isLinkable;
    const rel = target === '_blank' ? 'noopener noreferrer' : undefined;
    const [hasHref, setHasHref] = useState(false);
    const alwaysLinkableLink = useRef<HTMLAnchorElement>(null);
    const intl = useIntl();
    const addHref = (): void => {
        if (path && alwaysLinkableLink?.current) {
            setHasHref(true);
            alwaysLinkableLink.current.href = handleLocaleUrl(path, intl.locale);
        }
    };

    const classes = classnames(buyerLinkType && styles[buyerLinkType], className);

    if (!path) {
        return <>{children}</>;
    }

    if (isLinkable) {
        return (
            // eslint-disable-next-line react/forbid-elements
            <a
                aria-label={ariaLabel}
                aria-describedby={ariaDescribedBy}
                role={role}
                href={handleLocaleUrl(path, intl.locale)}
                onClick={onClick}
                data-tn={dataTn}
                data-pk={dataPk}
                className={classes}
                style={style}
                ref={alwaysLinkableLink}
                rel={rel}
                target={target}
            >
                {children}
            </a>
        );
    }

    if (variant === NO_CONTENT_LINK) {
        return null;
    }

    if (variant === PARTIALLY_LINKABLE_LINK) {
        return (
            <span data-tn={dataTn} data-pk={dataPk} className={classes} style={style}>
                {children}
            </span>
        );
    }

    if (variant === ALWAYS_LINKABLE_LINK) {
        return (
            // eslint-disable-next-line
            <a
                aria-label={ariaLabel}
                aria-describedby={ariaDescribedBy}
                onClick={(e: MouseEvent<HTMLElement>) => {
                    onClick(e);
                    addHref();
                }}
                tabIndex={hasHref ? undefined : tabIndex} // links w/o href need tabindex
                role={role}
                onFocus={addHref}
                onMouseOver={addHref}
                onTouchStart={addHref}
                data-tn={dataTn}
                data-pk={dataPk}
                className={classes}
                style={style}
                ref={alwaysLinkableLink}
                rel={rel}
                target={target}
            >
                {children}
            </a>
        );
    }

    return null;
};
