/**
 * @generated SignedSource<<7b20527a61a078455731b088783e7a65>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProductCarousel_itemSearch$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ProductSwiper_itemSearch">;
  readonly " $fragmentType": "ProductCarousel_itemSearch";
};
export type ProductCarousel_itemSearch$key = {
  readonly " $data"?: ProductCarousel_itemSearch$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProductCarousel_itemSearch">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProductCarousel_itemSearch",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProductSwiper_itemSearch"
    }
  ],
  "type": "ItemSearchQueryConnection",
  "abstractKey": null
};

(node as any).hash = "ef120cfc0edcfdd82bd9f34928fc7ced";

export default node;
