import dibsCss from 'dibs-css';
import classnames from 'classnames';
import { type FunctionComponent } from 'react';

type CloseProps = {
    className?: string;
};

const Close: FunctionComponent<CloseProps> = props => {
    return (
        <svg
            className={classnames(dibsCss.dibsIcon, props.className)}
            data-tn={'close'}
            viewBox="0 0 250 250"
        >
            <rect
                x="120.1"
                y="-43.6"
                transform="matrix(0.7087 -0.7055 0.7055 0.7087 -51.1131 123.9573)"
                width="8.8"
                height="334.9"
            />
            <rect
                x="120.2"
                y="-43.6"
                transform="matrix(0.7055 0.7087 -0.7087 0.7055 124.4345 -51.8261)"
                width="8.8"
                height="334.9"
            />
        </svg>
    );
};
export default Close;
