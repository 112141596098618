import { type FC, Fragment, type ReactNode } from 'react';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import { FormattedMessage } from 'dibs-react-intl';
import { SeoLink } from 'dibs-seo/exports/SeoLink';
import { PARTIALLY_LINKABLE_LINK } from 'dibs-elements/exports/SeoLink';
import { type QuickViewCreators_quickViewDisplay$data } from './__generated__/QuickViewCreators_quickViewDisplay.graphql';
import { CREATOR_UNKNOWN } from '../constants';

type QuickViewCreatorsProps = {
    quickViewDisplay: QuickViewCreators_quickViewDisplay$data;
    className?: string;
};

export const copy = {
    by: (
        <FormattedMessage id="dc.searchProductTile.by" defaultMessage="By">
            {msg => <Fragment>{msg}</Fragment>}
        </FormattedMessage>
    ),
};

const QuickViewCreators: FC<QuickViewCreatorsProps> = ({ quickViewDisplay, className }) => {
    const creatorsList = quickViewDisplay?.creators;

    if (!creatorsList || !creatorsList.length) {
        return null;
    }

    const creators = creatorsList.map<ReactNode>(creator => {
        if (!creator || creator.displayName === CREATOR_UNKNOWN) {
            return null;
        }

        return (
            <SeoLink
                variant={PARTIALLY_LINKABLE_LINK}
                key={creator.displayName}
                linkData={creator.linkData}
                dataTn="quick-view-creator-link"
            >
                {creator.displayName}
            </SeoLink>
        );
    });

    return (
        <div data-tn="quick-view-creators" className={className}>
            {copy.by} {creators.reduce((prev, curr) => [prev, ', ', curr])}
        </div>
    );
};

export default createFragmentContainer(QuickViewCreators, {
    quickViewDisplay: graphql`
        fragment QuickViewCreators_quickViewDisplay on QuickViewDisplayType {
            creators {
                displayName
                linkData {
                    ...SeoLink_linkData
                }
            }
        }
    `,
});
