import { useIntl, defineMessages, type MessageDescriptor } from 'dibs-react-intl';
import { type CategorySegmentsType } from '../QuickViewCategoryParagraph';
import { PAGE_TYPE } from 'dibs-constants/exports/pageTypes';
import { type Maybe } from 'dibs-ts-utils/exports/Maybe';
import { type AppliedFilter, VALID_FILTER, type ValidFilterValue } from './useValidFilter';

const { PERIOD_GROUP_FILTER } = VALID_FILTER;

type BoolValueFilter = Exclude<ValidFilterValue, typeof PERIOD_GROUP_FILTER>;
type PeriodGroupValue = 'antique' | 'retro' | 'used' | 'vintage';

const periodGroupMessages = defineMessages({
    antique: {
        id: 'periodGroup.antique',
        defaultMessage: 'Antique',
    },
    retro: {
        id: 'buyPageKeyword.retro',
        defaultMessage: 'Retro',
    },
    used: {
        id: 'periodGroup.used',
        defaultMessage: 'Used',
    },
    vintage: {
        id: 'periodGroup.vintage',
        defaultMessage: 'Vintage',
    },
});

const appliedFilterMessages = defineMessages({
    estate: {
        id: 'appliedFilter.estate',
        defaultMessage: 'Estate',
    },
    topDesigner: {
        id: 'appliedFilter.topDesigner',
        defaultMessage: 'Designer',
    },
});

function useValidFilterRegExp({
    translate,
    filterName,
}: {
    translate: boolean;
    filterName: string;
}): RegExp {
    const intl = useIntl();
    const messages =
        filterName === PERIOD_GROUP_FILTER
            ? Object.values(periodGroupMessages)
            : Object.values(appliedFilterMessages);
    let filterValues = messages.map(({ defaultMessage }) => defaultMessage);

    if (translate) {
        filterValues = messages.reduce((allTranslatedMsg: string[], msg: MessageDescriptor) => {
            const translatedMsg = intl.formatMessage(msg);
            return [...allTranslatedMsg, translatedMsg];
        }, []);
    }

    return new RegExp(`\\b(${filterValues.join('|')})\\b`, 'i');
}

export function swapPeriodGroup({
    text,
    periodGroup,
    periodGroupRegExp,
}: {
    text: Maybe<string>;
    periodGroup: string;
    periodGroupRegExp: RegExp;
}): Maybe<string> {
    return text?.replace(periodGroupRegExp, periodGroup);
}

export function containsValidFilterValue(text: Maybe<string>, filterValueRegExp: RegExp): boolean {
    return !!text && filterValueRegExp.test(text);
}

// Returns supported filter value for modifying buy page product image alt texts and quickview
function useValidFilterValue({
    appliedFilter,
    validFilterRegExp,
}: {
    appliedFilter: AppliedFilter | null;
    validFilterRegExp: RegExp;
}): string | null {
    const intl = useIntl();
    const filterName = appliedFilter?.name || '';
    let isValidFilter;
    let filterValue;

    if (!appliedFilter) {
        return null;
    }

    if (filterName === PERIOD_GROUP_FILTER) {
        const periodGroup = appliedFilter?.values?.[0]?.displayName?.toLowerCase();
        isValidFilter = periodGroup && containsValidFilterValue(periodGroup, validFilterRegExp);
        filterValue = isValidFilter
            ? intl.formatMessage(periodGroupMessages[periodGroup as PeriodGroupValue])
            : null;
    } else {
        isValidFilter =
            Object.keys(appliedFilterMessages).includes(filterName) &&
            appliedFilter?.values?.[0]?.displayName === 'True';
        filterValue = isValidFilter
            ? intl.formatMessage(appliedFilterMessages[filterName as BoolValueFilter])
            : null;
    }

    return isValidFilter && filterValue ? filterValue : null;
}

export function useAppliedFilterAltText({
    title,
    appliedFilter,
}: {
    title: string;
    appliedFilter: AppliedFilter | null;
}): string {
    const filterName = appliedFilter?.name || '';
    const untranslatedRegExp = useValidFilterRegExp({ translate: false, filterName });
    const translatedRegExp = useValidFilterRegExp({ translate: true, filterName });
    const validFilterValue = useValidFilterValue({
        appliedFilter,
        validFilterRegExp: untranslatedRegExp,
    });
    const titleHasValidFilterValue = containsValidFilterValue(title, translatedRegExp);

    if (validFilterValue) {
        if (filterName === PERIOD_GROUP_FILTER && titleHasValidFilterValue) {
            const swappedTitle = swapPeriodGroup({
                text: title,
                periodGroup: validFilterValue,
                periodGroupRegExp: translatedRegExp,
            });

            if (swappedTitle) {
                return swappedTitle;
            }
        } else if (!titleHasValidFilterValue) {
            return `${validFilterValue} ${title}`;
        }
    }

    return title;
}

export function useAppliedFilterQuickView({
    pageType,
    appliedFilter,
    pageHeading,
    categorySegments,
}: {
    pageType?: Maybe<string>;
    appliedFilter?: Maybe<AppliedFilter>;
    pageHeading?: Maybe<string>;
    categorySegments: CategorySegmentsType;
}): Maybe<string> {
    const filterName = appliedFilter?.name || '';
    const untranslatedRegExp = useValidFilterRegExp({ translate: false, filterName });
    const translatedRegExp = useValidFilterRegExp({ translate: true, filterName });
    const validFilterValue = useValidFilterValue({
        appliedFilter: appliedFilter || null,
        validFilterRegExp: untranslatedRegExp,
    });
    const pageHeadingContainsFilterValue = containsValidFilterValue(pageHeading, translatedRegExp);

    if (pageType === PAGE_TYPE.BUY && validFilterValue && categorySegments) {
        const { period, origin, style } = categorySegments;
        const paragraphSegments = [period, origin, style];
        let newPageHeading = pageHeading;

        if (filterName === PERIOD_GROUP_FILTER && pageHeadingContainsFilterValue) {
            // https://1stdibs.atlassian.net/browse/GROWTH-6811?focusedCommentId=459423
            newPageHeading = swapPeriodGroup({
                text: pageHeading,
                periodGroup: validFilterValue,
                periodGroupRegExp: translatedRegExp,
            });
        } else if (!pageHeadingContainsFilterValue) {
            paragraphSegments.push(validFilterValue);
        }

        return [...paragraphSegments, newPageHeading].filter(Boolean).join(' ');
    } else {
        return null;
    }
}
