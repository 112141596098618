import dibsCss from 'dibs-css';
import classnames from 'classnames';
import { type FunctionComponent } from 'react';

type InfoCircleProps = {
    className?: string;
};

const InfoCircle: FunctionComponent<InfoCircleProps> = props => {
    return (
        <svg
            className={classnames(dibsCss.dibsIcon, props.className)}
            data-tn={'info-circle'}
            viewBox="0 0 250 250"
        >
            <path d="M133.7 181.8c-3.5 0-6.2-.7-7.8-2-1.6-1.3-2.4-3.7-2.4-7.4 0-1.5.2-3.4.7-5.8s1-4.8 1.7-7.3l10-40.2c.5-2 .9-3.9 1.2-5.9.3-2 .4-3.5.4-4.6 0-4.1-1.2-7.5-3.5-9.9-2.4-2.5-6.1-3.6-11.2-3.6-2.3 0-5.3.5-9.3 1.6-3.9 1.1-8.3 2.8-13.2 4.9l-1.4 6c1.2-.3 3-.7 5.2-1.2 2.3-.4 4.1-.7 5.5-.7 3.5 0 6 .6 7.1 1.8 1.2 1.2 1.8 3.5 1.8 7.1 0 1.8-.2 3.9-.7 6.4-.5 2.6-1 4.9-1.5 7.1l-9.9 40.2c-.6 2.4-1 4.4-1.2 6-.3 1.6-.4 3.1-.4 4.5 0 3.8 1.3 7.1 3.7 9.6 2.6 2.7 6.3 3.9 11.3 3.9 2.5 0 5.4-.5 8.9-1.4 3.4-.9 8.1-2.6 13.9-4.8l1.4-6c-1.2.4-3 .8-5.1 1.2-2.3.4-4.1.5-5.2.5z" />
            <path d="M138.5 47.3c-3.2 0-6 1.3-8.3 3.8-2.4 2.6-3.5 5.6-3.5 9.1 0 3.2 1.2 6.2 3.5 8.8s5.1 3.8 8.3 3.8c3.4 0 6.4-1.4 8.7-4 2.4-2.7 3.5-5.8 3.5-9.4 0-3.2-1.2-6-3.4-8.5-2.3-2.4-5.3-3.6-8.8-3.6z" />
            <path d="M125.6 247.4c-67.2 0-121.9-54.7-121.9-121.9 0-67.3 54.7-122 121.9-122s121.9 54.7 121.9 121.9c0 67.3-54.7 122-121.9 122zm0-234.9c-62.3 0-112.9 50.7-112.9 112.9 0 62.3 50.7 112.9 112.9 112.9s112.9-50.7 112.9-112.9S187.9 12.5 125.6 12.5z" />
        </svg>
    );
};
export default InfoCircle;
