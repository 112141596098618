import {
    type FC,
    type ReactNode,
    type UIEventHandler,
    Children,
    useCallback,
    useLayoutEffect,
    useRef,
} from 'react';
import classnames from 'classnames';

import { useInternalScrollCarousel } from '../helpers/InternalScrollCarouselContext';
import { getGapClassName } from '../helpers/gapStyles/getGapClassName';
import { getScrollPaddingClassName } from '../helpers/gapStyles/getScrollPaddingClassName';
import { CarouselItem } from '../CarouselItem/CarouselItem';
import { BackFill } from '../BackFill/BackFill';
import { browser } from 'dibs-client-check';

import { type Animation, type Orientation } from '../types';

import styles from './ScrollContainer.scss';

type GoToIndex = ({ index }: { index: number; animation: Animation }) => void;

const SCROLL_POSITION_MAP = {
    horizontal: 'scrollLeft',
    vertical: 'scrollTop',
} as const;

const SCROLL_FULL_SIZE_MAP = {
    horizontal: 'scrollWidth',
    vertical: 'scrollHeight',
} as const;

const SCROLL_VISIBLE_SIZE_MAP = {
    horizontal: 'clientWidth',
    vertical: 'clientHeight',
} as const;

const SCROLL_TO_MAP = {
    horizontal: 'left',
    vertical: 'top',
} as const;

//Threshold to account for scroll inconsistencies between browsers
const SCROLL_THRESHOLD = 1;

const getItemSize = ({
    scrollContainer,
    orientation,
}: {
    scrollContainer: HTMLDivElement | null;
    orientation: Orientation;
}): number => {
    return scrollContainer?.querySelector('div')?.[SCROLL_VISIBLE_SIZE_MAP[orientation]] || 0;
};

const inScrollSetNormalization = (scrollContainer: HTMLDivElement, func: () => void): void => {
    //Some browsers itroduce jancky scroll behavior when setting scroll position with scoll snap active, this is a workaround
    //Currently known browsers that have this issue: Chrome
    const isNormalizationNeeded = browser.chrome();

    if (isNormalizationNeeded) {
        scrollContainer.setAttribute('style', 'scroll-snap-type: none;');
        func();
        setTimeout(() => {
            scrollContainer.removeAttribute('style');
        }, 50);
    } else {
        func();
    }
};

export const ScrollContainer: FC<{ children: ReactNode }> = ({ children }) => {
    const carouselRef = useRef<HTMLDivElement>(null);

    const isCarouselInit = useRef(false);

    const {
        backfill,
        carouselOrientation: orientation,
        isEndSnapScroll,
        animation,
        enableScroll,
        isBackfillInit,
        setIsBackfillInit,
        currentIndex,
        stepSize,
        totalItems,
        isInfinite,
        itemsToShow,
        setCurrentIndex,
        prevIndexRef,
        gapSize,
        scrollPadding,
    } = useInternalScrollCarousel();
    const prevBackfillStartCountRef = useRef(backfill.startCount);

    const isGoToIndexTriggered = useRef(false);
    const goToIndex = useCallback<GoToIndex>(
        ({ index, animation: _animation }) => {
            const scrollContainer = carouselRef.current;
            const itemSize = getItemSize({ scrollContainer, orientation });

            if (!scrollContainer || !itemSize || index === prevIndexRef.current) {
                return;
            }

            isGoToIndexTriggered.current = true;

            let adjustedIndex = index;
            const endIndex = totalItems - 1;
            const firstItemIndex = backfill.startCount;
            const lastItemIndex = endIndex + backfill.startCount;
            const currentScrollPosition = scrollContainer[SCROLL_POSITION_MAP[orientation]];

            if (isInfinite) {
                const isEndSnapAdjustmentNeeded =
                    isEndSnapScroll &&
                    index - backfill.startCount >= endIndex - (totalItems % itemsToShow);

                if (backfill.startCount > stepSize) {
                    //different handling when navigating synced carousels
                    if (index > lastItemIndex && prevIndexRef.current - totalItems > 0) {
                        //different handling when navigating end backfill items
                        scrollContainer[SCROLL_POSITION_MAP[orientation]] =
                            (prevIndexRef.current - totalItems) * itemSize;
                        adjustedIndex = index - totalItems;
                        setCurrentIndex(adjustedIndex);
                    } else if (index === firstItemIndex && prevIndexRef.current === lastItemIndex) {
                        //different handling when index reset to first item happens
                        if (Math.abs(currentScrollPosition - index * itemSize) > SCROLL_THRESHOLD) {
                            //scroll to previos snap position only if scroll position is not already at the right position
                            scrollContainer[SCROLL_POSITION_MAP[orientation]] =
                                (prevIndexRef.current - totalItems) * itemSize;
                        }
                        adjustedIndex = index;
                        setCurrentIndex(adjustedIndex);
                    } else if (index === 0) {
                        //different handling when navigating to first item
                        scrollContainer[SCROLL_POSITION_MAP[orientation]] =
                            (prevIndexRef.current + totalItems) * itemSize;
                        adjustedIndex = index + totalItems;
                        setCurrentIndex(adjustedIndex);
                    } else if (prevIndexRef.current === 1 && index === totalItems) {
                        //different handling when index reset to last item happens
                        if (Math.abs(currentScrollPosition - index * itemSize) > SCROLL_THRESHOLD) {
                            //scroll to previos snap position only if scroll position is not already at the right position
                            scrollContainer[SCROLL_POSITION_MAP[orientation]] =
                                (prevIndexRef.current + totalItems) * itemSize;
                        }
                        adjustedIndex = index;
                        setCurrentIndex(adjustedIndex);
                    } else {
                        adjustedIndex = index;
                        setCurrentIndex(adjustedIndex);
                    }
                } else if (isEndSnapAdjustmentNeeded) {
                    adjustedIndex -= itemsToShow - (totalItems % stepSize);
                    setCurrentIndex(index);
                } else {
                    adjustedIndex = index;
                    setCurrentIndex(adjustedIndex);
                }
            } else {
                setCurrentIndex(index);
            }

            const newScrollPosition = itemSize * adjustedIndex;

            if (Math.abs(currentScrollPosition - newScrollPosition) <= SCROLL_THRESHOLD) {
                //do not scroll if scroll position is already at the right position
                isGoToIndexTriggered.current = false;
                return;
            }

            if (_animation === 'none') {
                scrollContainer[SCROLL_POSITION_MAP[orientation]] = newScrollPosition;
            } else if (_animation === 'slide') {
                scrollContainer.scrollTo({
                    [SCROLL_TO_MAP[orientation]]: newScrollPosition,
                    behavior: 'smooth',
                });
            }
        },
        [
            orientation,
            backfill.startCount,
            itemsToShow,
            stepSize,
            totalItems,
            isEndSnapScroll,
            isInfinite,
            setCurrentIndex,
            prevIndexRef,
        ]
    );

    useLayoutEffect(() => {
        const scrollContainer = carouselRef.current;
        const itemSize = getItemSize({ scrollContainer, orientation });

        /**
         * Will init only visible carousels (carousels outside viewport will be initialized).
         * Affects cases when carousels have display: none or visibility: hidden
         */
        if (!itemSize && !isCarouselInit.current) {
            return;
        }

        if (!isBackfillInit) {
            //backfill items are rendered only on client side, we need to skip one render cycle to avoid flickering
            setIsBackfillInit(true);
            return;
        }

        if (backfill.startCount && !isCarouselInit.current) {
            goToIndex({
                index: currentIndex === 0 ? backfill.startCount : currentIndex,
                animation: 'none',
            });
        } else if (prevBackfillStartCountRef.current !== backfill.startCount) {
            goToIndex({
                index: currentIndex - prevBackfillStartCountRef.current + backfill.startCount,
                animation: 'none',
            });
        } else {
            goToIndex({
                index: currentIndex,
                animation,
            });
        }
        isCarouselInit.current = true;
        prevBackfillStartCountRef.current = backfill.startCount;
    }, [
        backfill.show,
        backfill.startCount,
        currentIndex,
        goToIndex,
        animation,
        isBackfillInit,
        setIsBackfillInit,
        orientation,
    ]);

    //used to detect if scrolling action is finished
    const scrollTimeout = useRef<NodeJS.Timeout>();

    const onScroll = useCallback<UIEventHandler<HTMLDivElement>>(
        event => {
            const scrollContainer = event.currentTarget;
            const itemSize = getItemSize({ scrollContainer, orientation });

            if (!itemSize) {
                return;
            }

            const scrollPosition = scrollContainer[SCROLL_POSITION_MAP[orientation]];
            const scrollFullSize = scrollContainer[SCROLL_FULL_SIZE_MAP[orientation]];
            const scrollVisibleSize = scrollContainer[SCROLL_VISIBLE_SIZE_MAP[orientation]];

            //intinite scroll handling
            if (isInfinite) {
                //aditional handling for fractional itemsToShow
                const itemsToShowRemainer = itemsToShow - Math.floor(itemsToShow);
                const remainingItemSize = itemsToShowRemainer
                    ? Math.ceil(itemSize * (1 - itemsToShowRemainer))
                    : 0;

                const isStart = isEndSnapScroll
                    ? scrollPosition <= remainingItemSize
                    : scrollPosition <= 0;
                if (isStart) {
                    inScrollSetNormalization(scrollContainer, () => {
                        //if we are at the beginning of the carousel, scroll to the end
                        scrollContainer[SCROLL_POSITION_MAP[orientation]] =
                            totalItems * itemSize + (isEndSnapScroll ? remainingItemSize : 0);
                    });
                }

                const isEnd =
                    scrollFullSize - scrollPosition - scrollVisibleSize - remainingItemSize <=
                    SCROLL_THRESHOLD;

                if (isEnd) {
                    inScrollSetNormalization(scrollContainer, () => {
                        //if we are at the end of the carousel, scroll back to the beginning
                        scrollContainer[SCROLL_POSITION_MAP[orientation]] =
                            itemSize * backfill.startCount;
                    });
                }
            }
            /**
             * Too complicated scroll snap detection logic
             * TODO: implement snap event listener when it is supported https://drafts.csswg.org/css-scroll-snap-2/#snap-events
             */
            let index = Math.round((scrollPosition + SCROLL_THRESHOLD) / itemSize);

            const isEndWithoutBackfill =
                scrollFullSize -
                    scrollPosition -
                    itemSize * backfill.endCount -
                    scrollVisibleSize <=
                SCROLL_THRESHOLD;

            //Adjust index when navigating to end snap item
            if (isEndSnapScroll && isEndWithoutBackfill) {
                index = totalItems - (totalItems % stepSize) + backfill.startCount;
            }

            if (scrollTimeout.current) {
                clearTimeout(scrollTimeout.current);
            }

            scrollTimeout.current = setTimeout(() => {
                if (
                    currentIndex !== index &&
                    /**
                     * Not infinite carousel has items that are impossible to scroll to. They can be active
                     * only by directly navigating to the item with goToIndex. Need to exclude this case in scroll
                     * handler since it causes inacurate active index.
                     */
                    !(!isInfinite && isEndWithoutBackfill && isGoToIndexTriggered.current)
                ) {
                    setCurrentIndex(index);
                }
                isGoToIndexTriggered.current = false;
            }, 50);
        },
        [
            orientation,
            isInfinite,
            itemsToShow,
            backfill.startCount,
            backfill.endCount,
            isEndSnapScroll,
            currentIndex,
            stepSize,
            totalItems,
            setCurrentIndex,
        ]
    );

    return (
        <div className={styles.carouselWrapper}>
            <div
                ref={carouselRef}
                className={classnames(
                    styles.carousel,
                    styles[getGapClassName({ gapSize, orientation })],
                    styles[getScrollPaddingClassName({ scrollPadding, orientation })],
                    styles[orientation],
                    styles[animation],
                    {
                        [styles.disableScroll]: !enableScroll,
                    }
                )}
                onScroll={onScroll}
            >
                <BackFill type="start">{children}</BackFill>

                {Children.map(children, (child, index) => (
                    <CarouselItem
                        key={index}
                        snapIndex={index}
                        trueIndex={index + backfill.startCount}
                    >
                        {child}
                    </CarouselItem>
                ))}

                <BackFill type="end">{children}</BackFill>
            </div>
        </div>
    );
};
