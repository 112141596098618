import { Fragment, type FC, useMemo } from 'react';
import { useFragment, graphql } from 'react-relay';

import { SeoLink } from 'dibs-elements/exports/SeoLink';
import { useClientState } from 'dibs-react-hooks/exports/useClientState';
import { LazyImage, type LazyImageProps } from '../../../LazyImage/LazyImage';
import { type ImageSizeType } from '../../../types/StyleSizes';
import styles from '../../styles.scss';

import { getPhotoImgProps } from './helpers';
import { useAppliedFilterAltText } from '../../../helpers/useValidFilterHelpers';
import { useValidFilter } from '../../../helpers/useValidFilter';
import { LazyThumbVideo } from './LazyThumbVideo';

import { type SwiperItem_photo$key } from './__generated__/SwiperItem_photo.graphql';
import { type SwiperItem_viewer$key } from './__generated__/SwiperItem_viewer.graphql';
import { type SwiperItem_itemSearch$key } from './__generated__/SwiperItem_itemSearch.graphql';

export interface ItemProps {
    disableLinks?: boolean;
    onClick?: () => void;
    pdpUrl: string;
    title: string;
    topQuery?: string;
    containerClassName?: string;
    srcSetSizes?: string;
    srcSetWidths?: Array<number>;
    srcSetQuality?: number;
    useLoFiLazyLoader: boolean;
    imageSize: ImageSizeType;
    imageLoadVerticalOffset?: number;
    onContentLoaded?: () => void;
}
interface Props extends ItemProps {
    index: number;
    useEagerImageLoad: boolean;
    photo: SwiperItem_photo$key;
    itemSearch: SwiperItem_itemSearch$key | null | undefined;
    viewer?: SwiperItem_viewer$key | null | undefined;
    itemId?: string | null;
}

const photoFragment = graphql`
    fragment SwiperItem_photo on ItemPhoto {
        ...helpers_getPhotoImgProps_photo
    }
`;

const viewerFragment = graphql`
    fragment SwiperItem_viewer on Viewer {
        ...LazyThumbVideo_viewer
    }
`;

const itemSearchFragment = graphql`
    fragment SwiperItem_itemSearch on ItemSearchQueryConnection {
        ...useValidFilter_itemSearch
    }
`;

export const SwiperItem: FC<Props> = ({
    viewer: viewerRef,
    photo: photoRef,
    itemSearch: itemSearchRef,
    itemId,
    index,
    useEagerImageLoad,
    containerClassName,
    disableLinks,
    onClick,
    pdpUrl,
    srcSetSizes,
    srcSetWidths,
    srcSetQuality,
    imageSize,
    title,
    topQuery,
    imageLoadVerticalOffset,
    onContentLoaded,
    useLoFiLazyLoader,
}) => {
    const viewer = useFragment(viewerFragment, viewerRef || null);
    const photo = useFragment(photoFragment, photoRef || null);
    const itemSearch = useFragment(itemSearchFragment, itemSearchRef || null);
    const validFilter = useValidFilter({ itemSearch });
    const photoAltText = useAppliedFilterAltText({ title, appliedFilter: validFilter });
    const isClient = useClientState();

    const swiperItem = useMemo(() => {
        let lazyImageProps: Partial<LazyImageProps> | undefined = undefined;
        let content = <Fragment />;

        if (photo) {
            lazyImageProps = getPhotoImgProps(photo, srcSetSizes, srcSetWidths, srcSetQuality);
            content = (
                <img
                    className={styles.swiperImage}
                    alt={photoAltText}
                    data-tn="product-image"
                    title={index === 0 ? topQuery : undefined}
                    {...lazyImageProps.srcSetAttributes}
                    src={lazyImageProps.src}
                />
            );
        }

        // Use video thumbnail only if this is first item in swiper
        // Don't choose video as content during server render
        if (viewer && itemId && isClient) {
            content = (
                <LazyThumbVideo viewer={viewer} itemId={itemId} poster={lazyImageProps?.src}>
                    {content}
                </LazyThumbVideo>
            );
        }

        return useEagerImageLoad ? (
            content
        ) : (
            <>
                <LazyImage
                    className={styles.swiperImage}
                    alt={title}
                    offsetVertical={imageLoadVerticalOffset}
                    onImageLoad={onContentLoaded}
                    useLoFiLazyLoader={useLoFiLazyLoader}
                    imageSize={imageSize}
                    {...lazyImageProps}
                />
                {index === 0 && <noscript>{content}</noscript>}
            </>
        );
    }, [
        photo,
        viewer,
        index,
        itemId,
        useEagerImageLoad,
        title,
        topQuery,
        imageLoadVerticalOffset,
        onContentLoaded,
        useLoFiLazyLoader,
        imageSize,
        srcSetSizes,
        srcSetWidths,
        srcSetQuality,
        photoAltText,
        isClient,
    ]);

    const swiperItemWithLink = (
        <SeoLink
            dataTn="thumb-image-link"
            linkData={{ path: pdpUrl, isLinkable: false }}
            onClick={onClick}
            className={styles.swiperLink}
        >
            <div className={containerClassName} data-tn="product-image-container">
                {swiperItem}
            </div>
        </SeoLink>
    );

    return disableLinks ? swiperItem : swiperItemWithLink;
};
