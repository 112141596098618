import { type FC, type ReactNode } from 'react';
import { FormattedNumber } from 'dibs-react-intl';
import { priceVariabilityLabel } from './messages';

// Styles
import styles from './styles.scss';

type PriceLabelProps = {
    currency: string;
    label?: ReactNode;
    price: number;
    quantityDisplay?: string | null;
    showPriceVariability?: boolean | null;
};

export const PriceLabel: FC<PriceLabelProps> = ({
    price,
    label,
    quantityDisplay,
    currency,
    showPriceVariability = false,
}) => {
    const fractionDigits = price < 1 ? 2 : 0; // AUCTIONS-1545 show cents if price is under 1 currency unit
    const priceIntl = (
        <FormattedNumber
            value={price}
            currency={currency}
            style="currency"
            minimumFractionDigits={fractionDigits}
            maximumFractionDigits={fractionDigits}
        />
    );

    return (
        <div className={styles.priceLabel} data-tn="price">
            {showPriceVariability && priceVariabilityLabel}
            {showPriceVariability ? ' ' : ''}
            {priceIntl}
            {label ? ' ' : ''}
            {label}
            {quantityDisplay ? ' ' : ''}
            {quantityDisplay}
        </div>
    );
};
