import { type FC, type ReactElement, createContext, useState, useEffect, useCallback } from 'react';
import { useDebouncedCallback } from 'dibs-react-hooks/exports/useDebouncedCallback';

export type QuickView = {
    openQuickView?: (index: number) => void;
    closeQuickView?: () => void;
    activeQuickView?: number | null;
};

export const QuickViewContext = createContext<QuickView>({});

type Props = {
    children: ReactElement;
};

const QuickViewProvider: FC<Props> = ({ children }) => {
    const [activeQuickView, setActiveQuickView] = useState<number | null>(null);

    const openQuickView = useCallback((index: number): void => setActiveQuickView(index), []);
    const closeQuickView = (): void => setActiveQuickView(null);
    const [onResize] = useDebouncedCallback(closeQuickView);

    useEffect(() => {
        window.addEventListener('resize', onResize);

        return () => window.removeEventListener('resize', onResize);
    }, [onResize]);

    return (
        <QuickViewContext.Provider value={{ openQuickView, closeQuickView, activeQuickView }}>
            {children}
        </QuickViewContext.Provider>
    );
};

export default QuickViewProvider;
