import { type ScrollPadding, type Orientation } from '../../types';

export const getScrollPaddingClassName = ({
    scrollPadding,
    orientation,
}: {
    scrollPadding: ScrollPadding;
    orientation: Orientation;
}): `${ScrollPadding}${Capitalize<Orientation>}ScrollPadding` =>
    `${scrollPadding}${
        (orientation[0].toUpperCase() + orientation.slice(1)) as Capitalize<Orientation>
    }ScrollPadding`; // e.g. `smallHorizontalScrollPadding` or `smallVerticalScrollPadding`
