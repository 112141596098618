import dibsCss from 'dibs-css';
import classnames from 'classnames';
import { type FunctionComponent } from 'react';

type ArrowUpProps = {
    className?: string;
};

const ArrowUp: FunctionComponent<ArrowUpProps> = props => {
    return (
        <svg
            className={classnames(dibsCss.dibsIcon, props.className)}
            data-tn={'arrow-up'}
            viewBox="0 -8.5 32 32"
        >
            <path d="M0 14c0-.3.1-.6.3-.8l15-13c.4-.3.9-.3 1.3 0l15 13c.4.4.5 1 .1 1.4-.4.4-1 .5-1.4.1L16 2.3 1.7 14.7c-.4.4-1 .3-1.4-.1-.2-.2-.3-.4-.3-.6z" />
        </svg>
    );
};
export default ArrowUp;
