import {
    type FC,
    createContext,
    useContext,
    type Dispatch,
    type SetStateAction,
    type MutableRefObject,
    type ReactNode,
    useState,
    useMemo,
    useRef,
    useCallback,
} from 'react';

import {
    type Backfill,
    type GapSize,
    type ScrollPadding,
    type Orientation,
    type Animation,
} from '../types';
import { useScrollCarousel } from './ScrollCarouselContext';

type InternalScrollCarouselContextType = {
    carouselOrientation: Orientation;
    itemsToShow: number;
    stepSize: number;
    currentIndex: number;
    totalItems: number;
    isInfinite: boolean;
    backfill: Backfill;
    isEndSnapScroll: boolean;
    setCurrentIndex: (index: number) => void;
    hasMultipleSlides: boolean;
    isBackfillInit: boolean;
    setIsBackfillInit: Dispatch<SetStateAction<boolean>>;
    gapSize: GapSize;
    scrollPadding: ScrollPadding;
    animation: Animation;
    enableScroll: boolean;
    prevIndexRef: MutableRefObject<number>;
};

const InternalScrollCarouselContext = createContext<InternalScrollCarouselContextType | null>(null);

export const useInternalScrollCarousel = (): InternalScrollCarouselContextType => {
    const context = useContext(InternalScrollCarouselContext);
    if (!context) {
        throw new Error(
            'useInternalScrollCarousel must be used within a InternalScrollCarouselProvider'
        );
    }
    return context;
};

export const InternalScrollCarouselProvider: FC<{
    children: ReactNode;
    orientation?: Orientation;
    itemsToShow?: number;
    animation?: Animation;
    enableScroll?: boolean;
    //gapSize names should be identical to the ones in dibs-sassy/exports/_spacing
    gapSize?: GapSize;
    scrollPadding?: ScrollPadding;
}> = ({
    children,
    orientation = 'horizontal',
    itemsToShow = 1,
    animation = 'slide',
    enableScroll = false,
    gapSize = 'none',
    scrollPadding = 'none',
}) => {
    const {
        currentIndex,
        setCurrentIndex: _setCurrentIndex,
        totalItems,
        largestItemsToShow,
        stepSize,
        isInfinite,
        totalSlides,
    } = useScrollCarousel();

    if (stepSize !== 1 && stepSize !== Math.floor(itemsToShow)) {
        throw new Error(
            'Caorusel only supports single item scroll (stepSize = 1) or full page scroll (stepSize = Math.floor(itemsToShow))'
        );
    }

    if (animation === 'none' && enableScroll) {
        throw new Error('Scroll is not supported with animation none');
    }

    const [isBackfillInit, setIsBackfillInit] = useState(false);

    /**
     * Carousels with not full pages need to snap to the last item and perfrom end snap.
     * End snap scroll needs to be handled differently.
     */
    const isEndSnapScroll =
        stepSize === Math.floor(itemsToShow) &&
        Math.floor(itemsToShow) > 1 &&
        totalItems % stepSize !== 0;
    const hasMultipleSlides = totalItems > itemsToShow;

    const backfill = useMemo<Backfill>(() => {
        const show = isInfinite && hasMultipleSlides && isBackfillInit;

        let startCount = 0;
        let endCount = 0;

        if (show) {
            startCount = isEndSnapScroll
                ? Math.ceil(largestItemsToShow)
                : //if there are not enough items to fill the slide use the totalItems
                  Math.min(totalItems, Math.floor(largestItemsToShow));

            endCount = Math.ceil(itemsToShow);
        }

        return { show, startCount, endCount };
    }, [
        itemsToShow,
        largestItemsToShow,
        isInfinite,
        hasMultipleSlides,
        isEndSnapScroll,
        totalItems,
        isBackfillInit,
    ]);

    const prevIndexRef = useRef(0);
    const setCurrentIndex = useCallback(
        (index: number): void => {
            const totalItemsWithBackfill = totalItems + backfill.startCount + backfill.endCount;
            if (index < 0) {
                index = totalSlides * itemsToShow;
            } else if (index > totalItemsWithBackfill) {
                index = backfill.startCount;
            }
            _setCurrentIndex({ index, backfillStartCount: backfill.startCount });
            prevIndexRef.current = currentIndex;
        },
        [
            currentIndex,
            _setCurrentIndex,
            backfill.startCount,
            backfill.endCount,
            totalItems,
            totalSlides,
            itemsToShow,
        ]
    );

    const contextValue = useMemo(
        () => ({
            carouselOrientation: orientation,
            itemsToShow,
            stepSize,
            currentIndex,
            totalItems,
            isInfinite,
            backfill,
            isEndSnapScroll,
            setCurrentIndex,
            hasMultipleSlides,
            isBackfillInit,
            setIsBackfillInit,
            gapSize,
            scrollPadding,
            animation,
            enableScroll,
            prevIndexRef,
        }),
        [
            orientation,
            itemsToShow,
            stepSize,
            totalItems,
            currentIndex,
            isInfinite,
            backfill,
            isEndSnapScroll,
            setCurrentIndex,
            hasMultipleSlides,
            isBackfillInit,
            gapSize,
            scrollPadding,
            animation,
            enableScroll,
        ]
    );

    return (
        <InternalScrollCarouselContext.Provider value={contextValue}>
            {children}
        </InternalScrollCarouselContext.Provider>
    );
};
