import { type FC } from 'react';
import classnames from 'classnames';

import dibsCss from 'dibs-css';
import styles from './styles.scss';

type Props = {
    message?: string | null;
    reduceMargins?: boolean;
};

export const UrgencySignal: FC<Props> = ({ message, reduceMargins }) => {
    if (!message) {
        return null;
    }
    return (
        <div
            className={classnames(
                dibsCss.sassyFontBodyTypeHeavySizeXSmall,
                dibsCss.textAlertsecondary,
                {
                    [styles.urgencySignalMt]: !reduceMargins, // negative margin, cause element above have 9px margin-bottom,
                    [dibsCss.mbXxsmall]: !reduceMargins,
                    [dibsCss.mb2px]: reduceMargins,
                }
            )}
            data-tn="urgency-signal"
        >
            {message}
        </div>
    );
};
