/**
 * @generated SignedSource<<66369ddd89756e0088393ed4fb6fd879>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type helpers_getPhotoImgProps_photo$data = {
  readonly masterOrZoomPath: string | null;
  readonly placeholder: string | null;
  readonly smallPath: string | null;
  readonly " $fragmentType": "helpers_getPhotoImgProps_photo";
};
export type helpers_getPhotoImgProps_photo$key = {
  readonly " $data"?: helpers_getPhotoImgProps_photo$data;
  readonly " $fragmentSpreads": FragmentRefs<"helpers_getPhotoImgProps_photo">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "helpers_getPhotoImgProps_photo"
};

(node as any).hash = "b273d3e1e7f4508bab05020e428dd1de";

export default node;
