/**
 * @generated SignedSource<<3ed2ded394a97d3089c13065d5ef76d0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type QuickViewItem_item$data = {
  readonly linkData: {
    readonly path: string | null;
  };
  readonly quickViewDisplay: {
    readonly mobileTitle: string | null;
    readonly paragraphs: ReadonlyArray<{
      readonly key: string | null;
      readonly mobileText: string | null;
      readonly text: string | null;
    } | null> | null;
    readonly title: string | null;
    readonly " $fragmentSpreads": FragmentRefs<"QuickViewCreators_quickViewDisplay">;
  } | null;
  readonly title: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"QuickViewCategoryParagraph_item">;
  readonly " $fragmentType": "QuickViewItem_item";
};
export type QuickViewItem_item$key = {
  readonly " $data"?: QuickViewItem_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"QuickViewItem_item">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v1 = [
  {
    "kind": "Literal",
    "name": "formatType",
    "value": "MOBILE"
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "QuickViewItem_item",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "LinkData",
      "kind": "LinkedField",
      "name": "linkData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "path",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "QuickViewDisplayType",
      "kind": "LinkedField",
      "name": "quickViewDisplay",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": "mobileTitle",
          "args": (v1/*: any*/),
          "kind": "ScalarField",
          "name": "title",
          "storageKey": "title(formatType:\"MOBILE\")"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "QuickViewParagraph",
          "kind": "LinkedField",
          "name": "paragraphs",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "key",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "text",
              "storageKey": null
            },
            {
              "alias": "mobileText",
              "args": (v1/*: any*/),
              "kind": "ScalarField",
              "name": "text",
              "storageKey": "text(formatType:\"MOBILE\")"
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "QuickViewCreators_quickViewDisplay"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "QuickViewCategoryParagraph_item"
    }
  ],
  "type": "Item",
  "abstractKey": null
};
})();

(node as any).hash = "e8dc029b3b44153bd6a95ed998af3772";

export default node;
