import React, { type FC, type ComponentProps } from 'react';
import { useIntl } from 'dibs-react-intl';
import { LOCALE_DE, LOCALE_FR, LOCALE_ES, LOCALE_IT } from 'dibs-intl/exports/locales';

const Unavailable = React.lazy(() => import('dibs-icons/exports/legacy/Unavailable'));
const UnavailableDe = React.lazy(() => import('dibs-icons/exports/legacy/UnavailableDe'));
const UnavailableFr = React.lazy(() => import('dibs-icons/exports/legacy/UnavailableFr'));
const UnavailableIt = React.lazy(() => import('dibs-icons/exports/legacy/UnavailableIt'));
const UnavailableEs = React.lazy(() => import('dibs-icons/exports/legacy/UnavailableEs'));

type Props = ComponentProps<typeof Unavailable | typeof UnavailableFr | typeof UnavailableDe>;

export const LocalizedUnavailableIcon: FC<Props> = props => {
    const { locale } = useIntl();

    if (locale === LOCALE_DE) {
        return <UnavailableDe {...props} />;
    }

    if (locale === LOCALE_FR) {
        return <UnavailableFr {...props} />;
    }

    if (locale === LOCALE_IT) {
        return <UnavailableIt {...props} />;
    }

    if (locale === LOCALE_ES) {
        return <UnavailableEs {...props} />;
    }

    return <Unavailable {...props} />;
};
