import {
    useContext,
    useCallback,
    useRef,
    useState,
    useEffect,
    type FunctionComponent,
} from 'react';
import * as React from 'react';
import { createRefetchContainer, graphql, useFragment } from 'react-relay';

import classnames from 'classnames';
import * as tracking from 'dibs-tracking';
import { VisibilityTracker } from 'dibs-visibility-tracker/exports/VisibilityTracker';

import { ProductDetails } from './ProductDetails';
import { ProductSwiper } from './ProductSwiper';
import { SwipeIndicator } from '../../SwipeIndicator/SwipeIndicator';

import { TileContext } from '../../helpers/TileContext';
import { type ImageSizeType } from '../../types/StyleSizes';

type TileContextProps = {
    children: React.ReactNode;
    imageSize: ImageSizeType;
};

import { type ProductCarousel_item$data } from './__generated__/ProductCarousel_item.graphql';
import { type ProductCarousel_itemSearch$key } from './__generated__/ProductCarousel_itemSearch.graphql';
import { type ProductCarousel_viewer$key } from './__generated__/ProductCarousel_viewer.graphql';

import styles from '../styles.scss';
import dibsCss from 'dibs-css';

type RefetchArgs = {
    itemId: [string];
    fetchVideo: boolean;
    pageDisplayEnum: string; // graphql PageDisplayEnum enum;
    photosLimit?: number;
    viewInRoomLimit?: number;
};

export type ProductCarouselProps = {
    disableLinks?: boolean;
    pdpUrl: string;
    index: number;
    item: ProductCarousel_item$data;
    itemSearch: ProductCarousel_itemSearch$key;
    viewer: ProductCarousel_viewer$key;
    itemsPerRow: number;
    isHovering: boolean;
    isQuickViewActive: boolean;
    isMobile: boolean;
    title: string;
    topQuery: string;
    onClick?: () => void;
    onContentLoaded?: () => void;
    showQuickView?: boolean;
    openQuickView?: () => void;
    showLightBox?: boolean;
    dimensions?: string;
    showQuickViewIcon?: boolean;
    useLoFiLazyLoader: boolean;
    imageLoadVerticalOffset?: number;
    srcSetSizes?: string;
    srcSetWidths?: Array<number>;
    srcSetQuality?: number;
    hideDivider?: boolean;
    hideProductDetails?: boolean;
    hideImagePadding?: boolean;
    showSwipeIndicator?: boolean;
    // issues with ProductCarousel (relay = createRefetchContainer)
    // and Product (relay = createFragmentContainer) components
    relay: $TSFixMe;
};

const itemSearchFragment = graphql`
    fragment ProductCarousel_itemSearch on ItemSearchQueryConnection {
        ...ProductSwiper_itemSearch
    }
`;

const viewerFragment = graphql`
    fragment ProductCarousel_viewer on Viewer {
        ...ProductSwiper_viewer
    }
`;

export const ProductCarouselComponent: FunctionComponent<ProductCarouselProps> = ({
    disableLinks = false,
    pdpUrl,
    index,
    item,
    itemSearch: itemSearchRef,
    viewer: viewerRef,
    itemsPerRow = 3,
    isHovering,
    isQuickViewActive,
    isMobile,
    title,
    topQuery,
    onClick,
    onContentLoaded,
    showQuickView,
    openQuickView,
    showLightBox,
    dimensions,
    showQuickViewIcon,
    useLoFiLazyLoader = false,
    imageLoadVerticalOffset,
    srcSetSizes,
    srcSetWidths,
    srcSetQuality,
    hideDivider,
    hideProductDetails,
    hideImagePadding,
    showSwipeIndicator,
    relay,
}) => {
    const itemSearch = useFragment(itemSearchFragment, itemSearchRef);
    const viewer = useFragment(viewerFragment, viewerRef);

    const { imageSize } = useContext(TileContext) as TileContextProps;

    const imageWrapperRef = useRef(null);

    const itemServiceId = item?.serviceId;

    const [swiperWasClicked, setSwiperWasClicked] = useState(false);
    const [wasHovered, setWasHovered] = useState(false);
    const [isSwipeIndicatorVisible, setIsSwipeIndicatorVisible] = useState(showSwipeIndicator);
    const [hasPageChanged, setHasPageChanged] = useState(false);
    const [isRefetchFinished, setIsRefetchFinished] = useState(false);

    useEffect(() => {
        if (isHovering && !wasHovered) setWasHovered(true);
    }, [isHovering, wasHovered]);

    useEffect(() => {
        setIsSwipeIndicatorVisible(showSwipeIndicator);
    }, [showSwipeIndicator]);

    const getRefetchArgs = useCallback(
        ({ fetchVideo, pageDisplayEnum }: RefetchArgs) => ({
            fetchVideo,
            pageDisplayEnum,
            itemId: [itemServiceId],
            photosLimit: null,
            viewInRoomLimit: null,
        }),
        [itemServiceId]
    );

    const onSwiperPageChange = (nextPage: number): void => {
        tracking.trackEvent({
            category: 'results',
            action: 'results page image carousel arrow click',
            label: nextPage,
            additional: { itemId: itemServiceId },
            isInteractiveEvent: true,
        });

        setHasPageChanged(true);
        setIsSwipeIndicatorVisible(false);

        if (!swiperWasClicked) {
            relay.refetch(getRefetchArgs, null, () => setIsRefetchFinished(true));
            setSwiperWasClicked(true);
        }
    };

    const productClass = classnames(styles.product, {
        [styles.hasLightBox]: showLightBox,
        [styles.hasDivider]: !hideDivider,
    });

    const imageWrapperClass = classnames(dibsCss.relative, dibsCss.px0, {
        [dibsCss.py0]: hideImagePadding,
        [dibsCss.ptSmall]: !hideImagePadding,
        [dibsCss.pbXsmall]: !hideImagePadding,
    });

    const imageProps = {
        imageSize,
        disableLinks,
        onClick,
        pdpUrl,
        srcSetSizes,
        srcSetWidths,
        srcSetQuality,
        title,
        topQuery,
        imageLoadVerticalOffset,
        onContentLoaded,
        useLoFiLazyLoader,
    };

    const hasSingleCarouselImage = item?.carouselPhotos?.length === 1;

    const hasSingleImage = hasSingleCarouselImage && item?.viewInRoomPhotos?.length === 0;

    return (
        <div className={productClass} draggable="false">
            <div className={imageWrapperClass} ref={imageWrapperRef}>
                <ProductSwiper
                    item={item}
                    viewer={viewer}
                    itemSearch={itemSearch}
                    index={index}
                    arrowsVisible={isHovering && !isQuickViewActive}
                    isInitiallyVisibleTile={index < itemsPerRow}
                    isMobile={isMobile}
                    itemsPerRow={itemsPerRow}
                    swiperWasClicked={swiperWasClicked}
                    wasHovered={wasHovered}
                    onPageChange={onSwiperPageChange}
                    imageProps={imageProps}
                    hasSingleImage={hasSingleImage}
                    showViewInRoom={isRefetchFinished || hasSingleCarouselImage}
                />
                {showSwipeIndicator && !hasSingleImage && (
                    <SwipeIndicator isVisible={!hasPageChanged && !!isSwipeIndicatorVisible} />
                )}
            </div>
            {!hideProductDetails && (
                <ProductDetails
                    hideDivider={hideDivider}
                    showQuickView={showQuickView}
                    openQuickView={openQuickView}
                    showQuickViewIcon={showQuickViewIcon}
                    dimensions={dimensions}
                />
            )}
            {showSwipeIndicator && !hasSingleImage && (
                <VisibilityTracker
                    elementRef={imageWrapperRef}
                    onVisibilityChange={({ boundingClientRect, disconnect }) => {
                        if (boundingClientRect?.top < 0) {
                            setHasPageChanged(true);
                            disconnect();
                        }
                    }}
                    watchAfterFirstVisible
                />
            )}
        </div>
    );
};

export const ProductCarousel = createRefetchContainer(
    ProductCarouselComponent,
    {
        item: graphql`
            fragment ProductCarousel_item on Item
            @argumentDefinitions(
                photosLimit: { type: "Int", defaultValue: 2 }
                viewInRoomLimit: { type: "Int", defaultValue: 1 }
                fetchVideo: { type: "Boolean", defaultValue: false }
            ) {
                serviceId
                carouselPhotos: photos(limit: $photosLimit) {
                    __typename
                }
                viewInRoomPhotos(limit: $viewInRoomLimit) {
                    __typename
                }
                ...ProductSwiper_item
                    @arguments(
                        photosLimit: $photosLimit
                        viewInRoomLimit: $viewInRoomLimit
                        fetchVideo: $fetchVideo
                    )
            }
        `,
    },
    graphql`
        query ProductCarouselRefetchQuery(
            $itemId: [String]!
            $photosLimit: Int
            $viewInRoomLimit: Int
            $fetchVideo: Boolean!
        ) {
            viewer {
                itemRead(itemIds: $itemId) {
                    ... on Item {
                        ...ProductSwiper_item
                            @arguments(
                                photosLimit: $photosLimit
                                viewInRoomLimit: $viewInRoomLimit
                                fetchVideo: $fetchVideo
                            )
                    }
                }
            }
        }
    `
);
