/**
 * @generated SignedSource<<5c1a4f4b69e112bb61fbd3295ed1c696>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Shipping_item$data = {
  readonly " $fragmentSpreads": FragmentRefs<"useShippingPrice_item">;
  readonly " $fragmentType": "Shipping_item";
};
export type Shipping_item$key = {
  readonly " $data"?: Shipping_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"Shipping_item">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": "",
      "kind": "LocalArgument",
      "name": "buyerId"
    },
    {
      "defaultValue": true,
      "kind": "LocalArgument",
      "name": "fetchLiveShipmentQuote"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "fetchShippingCostInfo"
    },
    {
      "defaultValue": "",
      "kind": "LocalArgument",
      "name": "userCountryCode"
    },
    {
      "defaultValue": "",
      "kind": "LocalArgument",
      "name": "userZipCode"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "Shipping_item",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "buyerId",
          "variableName": "buyerId"
        },
        {
          "kind": "Variable",
          "name": "fetchLiveShipmentQuote",
          "variableName": "fetchLiveShipmentQuote"
        },
        {
          "kind": "Variable",
          "name": "fetchShippingCostInfo",
          "variableName": "fetchShippingCostInfo"
        },
        {
          "kind": "Variable",
          "name": "userCountryCode",
          "variableName": "userCountryCode"
        },
        {
          "kind": "Variable",
          "name": "userZipCode",
          "variableName": "userZipCode"
        }
      ],
      "kind": "FragmentSpread",
      "name": "useShippingPrice_item"
    }
  ],
  "type": "Item",
  "abstractKey": null
};

(node as any).hash = "2305d1dc95e34d734a2c538fe984cd47";

export default node;
