/**
 * @generated SignedSource<<106f95b11a9938ed0878b8e465208457>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useItemDisplayDimensions_user$data = {
  readonly preferences: {
    readonly measurementUnit: string | null;
  } | null;
  readonly " $fragmentType": "useItemDisplayDimensions_user";
};
export type useItemDisplayDimensions_user$key = {
  readonly " $data"?: useItemDisplayDimensions_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"useItemDisplayDimensions_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useItemDisplayDimensions_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "UserPreferences",
      "kind": "LinkedField",
      "name": "preferences",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "measurementUnit",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "927df55eab759f98d31cbd6e32669750";

export default node;
