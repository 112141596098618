import { type FC, lazy, Suspense, type ReactNode } from 'react';
import { useFragment, graphql } from 'react-relay';
import { Spinner } from 'dibs-elements/src/Spinner';
import { useClientState } from 'dibs-react-hooks/exports/useClientState';

import styles from './LazyThumbVideo.scss';

import { type LazyThumbVideo_viewer$key } from './__generated__/LazyThumbVideo_viewer.graphql';

type ThumbVideoProps = {
    viewer: LazyThumbVideo_viewer$key;
    itemId: string;
    poster?: string;
    children: ReactNode;
};

const viewerFragment = graphql`
    fragment LazyThumbVideo_viewer on Viewer {
        ...ThumbVideo_viewer
    }
`;

const ThumbVideo = lazy(() =>
    import('./ThumbVideo').then(module => ({ default: module.ThumbVideo }))
);

const TileSpinner = (): JSX.Element => (
    <div className={styles.lazyVideoSpinner}>
        <Spinner black />
    </div>
);

export const LazyThumbVideo: FC<ThumbVideoProps> = ({ viewer: viewerRef, ...props }) => {
    const viewer = useFragment(viewerFragment, viewerRef);
    const isClient = useClientState();

    return isClient ? (
        <Suspense fallback={<TileSpinner />}>
            <ThumbVideo {...props} viewer={viewer} />
        </Suspense>
    ) : null;
};
