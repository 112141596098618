/**
 * @generated SignedSource<<da6d2d278c7298a3a02af4ad43832ee6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProductCarousel_viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ProductSwiper_viewer">;
  readonly " $fragmentType": "ProductCarousel_viewer";
};
export type ProductCarousel_viewer$key = {
  readonly " $data"?: ProductCarousel_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProductCarousel_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProductCarousel_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProductSwiper_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "4a7734e1632bfd4eca7da8a8080b1d29";

export default node;
