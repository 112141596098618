/**
 * @generated SignedSource<<ccefa300e05d4a845f3cecdd390432aa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SwiperItem_itemSearch$data = {
  readonly " $fragmentSpreads": FragmentRefs<"useValidFilter_itemSearch">;
  readonly " $fragmentType": "SwiperItem_itemSearch";
};
export type SwiperItem_itemSearch$key = {
  readonly " $data"?: SwiperItem_itemSearch$data;
  readonly " $fragmentSpreads": FragmentRefs<"SwiperItem_itemSearch">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SwiperItem_itemSearch",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useValidFilter_itemSearch"
    }
  ],
  "type": "ItemSearchQueryConnection",
  "abstractKey": null
};

(node as any).hash = "223572b52604ed0a9d81b9d73297b437";

export default node;
