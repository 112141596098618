/**
 * @generated SignedSource<<0aa484b657b08300b516370ab7567877>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type Currency = "AUD" | "CAD" | "CHF" | "DKK" | "EUR" | "GBP" | "JPY" | "MXN" | "NOK" | "SEK" | "USD" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type PriceDisplay_transaction$data = {
  readonly isVatOrder: boolean | null;
  readonly mostRecentOffer: {
    readonly totalAmount: {
      readonly convertedAmountList: ReadonlyArray<{
        readonly amount: number | null;
        readonly currency: Currency | null;
      } | null> | null;
    } | null;
  } | null;
  readonly priceBookName: string | null;
  readonly summary: {
    readonly adjustedItemPrice: {
      readonly totalAmount: {
        readonly convertedAmountList: ReadonlyArray<{
          readonly amount: number | null;
          readonly currency: Currency | null;
        } | null> | null;
      } | null;
    } | null;
  } | null;
  readonly " $fragmentType": "PriceDisplay_transaction";
};
export type PriceDisplay_transaction$key = {
  readonly " $data"?: PriceDisplay_transaction$data;
  readonly " $fragmentSpreads": FragmentRefs<"PriceDisplay_transaction">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "FinancialAmount",
    "kind": "LinkedField",
    "name": "totalAmount",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ConvertedAmountList",
        "kind": "LinkedField",
        "name": "convertedAmountList",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "amount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "currency",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PriceDisplay_transaction",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Offer",
      "kind": "LinkedField",
      "name": "mostRecentOffer",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TransactionSummaryObject",
      "kind": "LinkedField",
      "name": "summary",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AdjustedPrice",
          "kind": "LinkedField",
          "name": "adjustedItemPrice",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isVatOrder",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "priceBookName",
      "storageKey": null
    }
  ],
  "type": "Transaction",
  "abstractKey": null
};
})();

(node as any).hash = "bcf7a29df7343f350d79d16abf68f226";

export default node;
