import { createContext, type ReactNode, type FunctionComponent } from 'react';

import { type ImageSizeType, type FontSizeType } from '../types/StyleSizes';

type TileContextProviderProps = {
    children: ReactNode;
    imageSize: ImageSizeType;
    fontSize?: FontSizeType;
};

export const TileContext = createContext<Partial<TileContextProviderProps>>({});

export const TileContextProvider: FunctionComponent<TileContextProviderProps> = props => {
    const { children, ...values } = props;

    return <TileContext.Provider value={values}>{children}</TileContext.Provider>;
};
