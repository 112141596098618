import { type FunctionComponent } from 'react';

// components
import { PriceAmount, type PriceAmountProps } from './PriceAmount';
import { PRICE_TYPES } from './constants';

interface Props extends PriceAmountProps {
    price?: number;
    showPriceLabel: boolean;
    percentageOff?: number;
}

/**
 * Price Components for an item with just a retail price
 */
export const RetailItemPrices: FunctionComponent<Props> = ({
    price,
    currency,
    quantityDisplay,
    showPriceLabel,
    hasPrivateOffer,
    appendElementToPrice,
    displayVatLabel,
    priceBookName,
    percentageOff,
}) => (
    /* RETAIL PRICE */
    <PriceAmount
        type={PRICE_TYPES.RETAIL}
        currency={currency}
        amount={price}
        showLabel={showPriceLabel}
        quantityDisplay={quantityDisplay}
        hasPrivateOffer={hasPrivateOffer}
        appendElementToPrice={appendElementToPrice}
        displayVatLabel={displayVatLabel}
        priceBookName={priceBookName}
        discount={percentageOff}
    />
);
