import { type FC } from 'react';
import { createFragmentContainer, graphql } from 'react-relay/legacy';

import { type SeoLink_linkData$data } from './__generated__/SeoLink_linkData.graphql';

import { SeoLink as SeoLinkElement, type SeoLinkProps } from 'dibs-elements/exports/SeoLink';

interface Props extends SeoLinkProps {
    linkData: SeoLink_linkData$data;
}

const SeoLinkComponent: FC<Props> = ({ linkData, ...props }) => {
    return <SeoLinkElement linkData={linkData} {...props} />;
};

export const SeoLink = createFragmentContainer(SeoLinkComponent, {
    linkData: graphql`
        fragment SeoLink_linkData on LinkData {
            path
            isLinkable
        }
    `,
});
