/**
 * @generated SignedSource<<f2f9a682cf5988914c81ede73788da13>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type Currency = "AUD" | "CAD" | "CHF" | "DKK" | "EUR" | "GBP" | "JPY" | "MXN" | "NOK" | "SEK" | "USD" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type PriceDisplay_sku$data = {
  readonly displayPrice?: ReadonlyArray<{
    readonly amountType: string | null;
    readonly convertedAmountList: ReadonlyArray<{
      readonly amount: number | null;
      readonly currency: Currency | null;
    } | null> | null;
    readonly isVatIncluded: boolean | null;
    readonly offerExpirationDate: string | null;
    readonly originalConvertedAmountList: ReadonlyArray<{
      readonly amount: number | null;
      readonly currency: Currency | null;
    } | null> | null;
    readonly percentageOff: string | null;
    readonly priceBookName: string | null;
    readonly privateOfferConvertedAmountList: ReadonlyArray<{
      readonly amount: number | null;
      readonly currency: Currency | null;
    } | null> | null;
    readonly privateOfferPercentageOff: string | null;
    readonly quantityDisplay: string | null;
    readonly showPriceLabel: boolean | null;
    readonly textType: string | null;
  } | null> | null;
  readonly " $fragmentType": "PriceDisplay_sku";
};
export type PriceDisplay_sku$key = {
  readonly " $data"?: PriceDisplay_sku$data;
  readonly " $fragmentSpreads": FragmentRefs<"PriceDisplay_sku">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "currency",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "amount",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "hasSkuId"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "isTrade"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "priceBookName"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "userCountryCode"
    },
    {
      "defaultValue": "",
      "kind": "LocalArgument",
      "name": "userId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "PriceDisplay_sku",
  "selections": [
    {
      "condition": "hasSkuId",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "buyerId",
              "variableName": "userId"
            },
            {
              "kind": "Variable",
              "name": "isTrade",
              "variableName": "isTrade"
            },
            {
              "kind": "Variable",
              "name": "priceBookName",
              "variableName": "priceBookName"
            },
            {
              "kind": "Variable",
              "name": "userCountryCode",
              "variableName": "userCountryCode"
            }
          ],
          "concreteType": "ItemDisplayPriceType",
          "kind": "LinkedField",
          "name": "displayPrice",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ConvertedAmountList",
              "kind": "LinkedField",
              "name": "originalConvertedAmountList",
              "plural": true,
              "selections": (v0/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ConvertedAmountList",
              "kind": "LinkedField",
              "name": "convertedAmountList",
              "plural": true,
              "selections": (v0/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ConvertedAmountList",
              "kind": "LinkedField",
              "name": "privateOfferConvertedAmountList",
              "plural": true,
              "selections": (v0/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "quantityDisplay",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "percentageOff",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "privateOfferPercentageOff",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "textType",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "amountType",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "showPriceLabel",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "offerExpirationDate",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isVatIncluded",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "priceBookName",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ]
    }
  ],
  "type": "ItemSkuType",
  "abstractKey": null
};
})();

(node as any).hash = "e7b8db3a0cb69e921fcc4656d08e2221";

export default node;
