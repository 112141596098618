/**
 * @generated SignedSource<<bae58886d8cdf14233f6cef64090bb9d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
export type VerticalType = "art" | "fashion" | "furniture" | "jewelry" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type shippingCostHelpers_item$data = {
  readonly shipmentQuotes: ReadonlyArray<{
    readonly isCalculated: boolean | null;
    readonly isComplimentary: boolean | null;
    readonly serviceMethod: {
      readonly region: {
        readonly regionName: string | null;
      } | null;
    } | null;
    readonly totalAmount: {
      readonly convertedAmounts: {
        readonly USD: number | null;
      } | null;
    } | null;
  } | null> | null;
  readonly vertical: VerticalType | null;
  readonly " $fragmentType": "shippingCostHelpers_item";
};
export type shippingCostHelpers_item$key = {
  readonly " $data"?: shippingCostHelpers_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"shippingCostHelpers_item">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "shippingCostHelpers_item"
};

(node as any).hash = "e8aff27d5d8155c3fa8acfe4caa047b8";

export default node;
