import { type FunctionComponent } from 'react';
import classnames from 'classnames';

import { QuickViewButton } from '../../QuickViewButton';
import styles from '../styles.scss';

type Props = {
    hideDivider?: boolean;
    showQuickView?: boolean;
    openQuickView?: () => void;
    showQuickViewIcon?: boolean;
    dimensions?: string;
};

export const ProductDetails: FunctionComponent<Props> = ({
    hideDivider,
    showQuickView,
    openQuickView,
    showQuickViewIcon,
    dimensions,
}: Props) => (
    <div
        className={classnames(styles.productDetailWrapper, {
            [styles.hideDivider]: hideDivider,
        })}
    >
        {showQuickView && (
            <QuickViewButton
                openQuickView={openQuickView}
                showQuickViewIcon={showQuickViewIcon}
                hideDivider={hideDivider}
            />
        )}
        {!!dimensions && (
            <div className={styles.dimensions} data-tn="dimensions">
                {dimensions}
            </div>
        )}
    </div>
);
