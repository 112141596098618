/**
 * @generated SignedSource<<fe32f74c567abf24298f324edbd257b5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SwatchSelectorMinimal_item$data = {
  readonly isMultiSku: boolean;
  readonly isRingItem: boolean | null;
  readonly variableAttributes: ReadonlyArray<{
    readonly serviceId: string | null;
    readonly variableAttributeData: {
      readonly swatchSort: ReadonlyArray<{
        readonly swatch: {
          readonly displayName: string | null;
          readonly imageUrl: string | null;
          readonly serviceId: string | null;
        } | null;
      } | null> | null;
    } | null;
  } | null> | null;
  readonly " $fragmentType": "SwatchSelectorMinimal_item";
};
export type SwatchSelectorMinimal_item$key = {
  readonly " $data"?: SwatchSelectorMinimal_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"SwatchSelectorMinimal_item">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "serviceId",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SwatchSelectorMinimal_item",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isMultiSku",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isRingItem",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "VariableAttribute",
      "kind": "LinkedField",
      "name": "variableAttributes",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "VariableAttributeSortType",
          "kind": "LinkedField",
          "name": "variableAttributeData",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "SwatchSort",
              "kind": "LinkedField",
              "name": "swatchSort",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Swatch",
                  "kind": "LinkedField",
                  "name": "swatch",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "displayName",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "imageUrl",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Item",
  "abstractKey": null
};
})();

(node as any).hash = "a815cb88c9dddb6fe9dee023d8f7fc64";

export default node;
