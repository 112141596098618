/**
 * @generated SignedSource<<1c6edc2b6eccf837b3f671a2beb6b37e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ItemTileBase_itemSearch$data = {
  readonly " $fragmentSpreads": FragmentRefs<"QuickViewItem_itemSearch">;
  readonly " $fragmentType": "ItemTileBase_itemSearch";
};
export type ItemTileBase_itemSearch$key = {
  readonly " $data"?: ItemTileBase_itemSearch$data;
  readonly " $fragmentSpreads": FragmentRefs<"ItemTileBase_itemSearch">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ItemTileBase_itemSearch",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "QuickViewItem_itemSearch"
    }
  ],
  "type": "ItemSearchQueryConnection",
  "abstractKey": null
};

(node as any).hash = "8fbf30901fa224c656bf72ef4f5a1474";

export default node;
