import {
    createContext,
    useContext,
    type FC,
    type ReactElement,
    type ReactNode,
    isValidElement,
} from 'react';
import { type Level } from './useHeading';
import Heading from './Heading';

export const Ctx = createContext(1);
// resetLevel should only be used if the React tree is rendered into a section
// of the document that already has headings beyond h1

export type HeadingComponentType = typeof Heading;

const HeadingLevel: FC<{
    resetLevel?: Level;
    children: ((HeadingComponent: HeadingComponentType) => ReactNode) | ReactElement | ReactNode[];
}> = ({ children, resetLevel }) => {
    const level = useContext(Ctx);
    return (
        <Ctx.Provider value={resetLevel || level + 1}>
            {/* if "children" is not a render callback, treat "children" in standard react fashion */}
            {isValidElement(children) || Array.isArray(children)
                ? children
                : (children as (HeadingComponent: HeadingComponentType) => ReactNode)(Heading)}
        </Ctx.Provider>
    );
};

export default HeadingLevel;
